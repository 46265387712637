import React, { useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function App() {

  const iframeRef = useRef(null);

  const enterFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,      // Activar autoplay
      autoplaySpeed: 5000, // Cambiar de diapositiva cada 5 segundos (5000 ms)
    };

  return (
    <div className="App">
      <header id="header" class="header fixed-top">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

          <a href="index.html" class="logo d-flex align-items-center">
            <img src="assets/img/logo.png" alt=""/>
            <span>Chutillos 2023 </span>
          </a>

          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto active"  onClick={enterFullscreen}>live streamer</a></li>
            </ul>
          </nav>
        </div>
      </header>
      <section id="hero" class="hero d-flex align-items-center">
         <div class="container">
         <iframe
             ref={iframeRef}
             allowFullScreen
             src="https://vdo.grupolimalive.com/live-stream-video-widget/universidad"
             height="720px"
             width="100%"
             frameBorder="0"
             autoPlay
             title="Video en Vivo"
            ></iframe>
         </div>


      </section>
      <div className="slider-container">
          <Slider {...settings}>
            <div className="slide">
              <p>Servicio de Facturación</p>
            </div>
            <div className="slide">
              <p>Servicio de Facturación conputarizada</p>
            </div>
            <div className="slide">
              <p>Servicio de Facturación conputarizada en linea</p>
            </div>
            <div className="slide">
              <p>Servicio de Facturación electronica</p>
            </div>
            <div className="slide">
              <p>Servicio de Sistemas</p>
            </div>
            <div className="slide">
              <p>Desarrollo de Portales Web</p>
            </div>
            <div className="slide">
              <p>Desarrollo de Aplicaciones</p>
            </div>
            <div className="slide">
              <p>Desarrollo de Sistemas</p>
            </div>
            <div className="slide">
              <p>Instalación de Cámaras de Seguridad</p>
            </div>
            <div className="slide">
              <p>Matenimiento y Instalación de Balanzas Mineras</p>
            </div>
            <div className="slide">
              <p>Instalacion y Mantenimiento de Aterramientos</p>
            </div>
            <div className="slide">
              <p>TV en Línea</p>
            </div>
            <div className="slide">
              <p>Radio en Línea</p>
            </div>
          </Slider>
        </div>
      <footer id="footer" class="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <h4>Vean Mas de nuestros servicios en nuestra web</h4>
            <p>El grupo de M@c-softins visitanos para  mas informacion </p>
          </div>

        </div>
      </div>
    </div>


    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span > <a href="https://mac-softins.com/">Mac-softins</a></span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Designed by <a href="https://ricardo.rojas.cruz.mac-softins.com/">Ricardo rojas cruz</a>
      </div>
    </div>
  </footer>
    </div>
  );
}

export default App;
